import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'

import { userMenuAtom } from '~atoms'
import { getUser, getAgeGroupSlug } from '~utilities'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Featured from '~components/home/featured'
import NavCards from '~components/home/nav-cards'
import NewAndTrending from '~components/home/new-and-trending'
import TrendingQuestions from '~components/home/trending-questions'
import SocialMedia from '~components/home/social-media'

const Home = props => {
  const { section, locale } = props.pageContext
  const key = locale.toLowerCase()
  const { location } = props

  const { home, settings } = props.data
  const { facebookLink, twitterLink } = settings

  // @TODO This is only here to make sure the component rerenders when the user updates their profile. Replace with a more robust solution later.
  const [isUserMenuOpen] = useAtom(userMenuAtom)

  const user = getUser()
  const children = user && user?.['custom:childrenDOBs'] ? JSON.parse(user['custom:childrenDOBs']) : null

  let userAgeGroups = []
  if (children && children.length > 0) {
    children.map(child => {
      // Pipeline Operator: https://github.com/tc39/proposal-pipeline-operator#introduction
      // equivalent to: `getAgeGroupSlug(dayjs().diff(child, 'months'))`

      let ageGroup = dayjs().diff(child, 'months') |> getAgeGroupSlug
      userAgeGroups = userAgeGroups.concat([ageGroup])
    })
  }

  let title = ''
  let heroHeadline = ''
  let heroHeadlineLine2 = ''
  let heroImage = ''
  let heroNavCard1 = ''
  let heroNavCard2 = ''
  let heroNavCard3 = ''
  let heroNavCard4 = ''
  let mission = {}
  let missionImage = ''

  let newDocument = {
    ...home
  }

  if (newDocument.Title) {
    title = newDocument.Title
  }

  if (newDocument.Hero_Headline) {
    heroHeadline = newDocument.Hero_Headline
  }

  if (newDocument.Hero_Headline_Line_2) {
    heroHeadlineLine2 = ' ' + newDocument.Hero_Headline_Line_2
  }

  const heroFullHeadline = (
    <>
      {heroHeadline}
      {heroHeadlineLine2 && <br className='hidden lg:block' />}
      {heroHeadlineLine2 && heroHeadlineLine2}
    </>
  )

  const getHeroImage = () => {
    if (userAgeGroups.includes('newborn')) {
      return newDocument.heroImageNewborn.fluid
    } else if (userAgeGroups.includes('baby')) {
      return newDocument.heroImageBaby.fluid
    } else if (userAgeGroups.includes('toddler')) {
      return newDocument.heroImageToddler.fluid
    } else if (userAgeGroups.includes('preschooler')) {
      return newDocument.heroImagePreschooler.fluid
    } else {
      return newDocument.Hero_Image.fluid
    }
  }

  const getFeaturedContent = () => {
    if (userAgeGroups.includes('newborn')) {
      return newDocument.featuredContentNewborn.contentful_id
    } else if (userAgeGroups.includes('baby')) {
      return newDocument.featuredContentBaby.contentful_id
    } else if (userAgeGroups.includes('toddler')) {
      return newDocument.featuredContentToddler.contentful_id
    } else if (userAgeGroups.includes('preschooler')) {
      return newDocument.featuredContentPreschooler.contentful_id
    } else {
      return newDocument.FeaturedContent.contentful_id
    }
  }

  if (newDocument.Hero_Image.fluid) {
    heroImage = {
      fluid: { ...getHeroImage() },
      style: {
        maxHeight: 592,
        position: 'absolute'
      }
    }
  }

  if (newDocument.Mission_Headline && newDocument.Mission_CTALabel) {
    mission = {
      headline: newDocument.Mission_Headline,
      CTALabel: newDocument.Mission_CTALabel
    }
  }

  if (newDocument.Mission_BackgroundImage) {
    missionImage = newDocument.Mission_BackgroundImage.file.url
  }

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO
        lang={locale}
        title={home?.metaTitle || home.title}
        description={home?.metaDescription}
        // @TODO get article author and pass in
        // author={author.fullName}
        // keywords={[]} // @TODO figure out if we are doing keywords!?
        // @TODO do we want to include the og:type website is the current default.
      />
      <div className='relative'>
        <div className='lg:absolute lg:z-0 lg:w-full lg:h-full'>
          <Image
            fluid={heroImage.fluid}
            className='lg:w-full lg:h-full'
            imgStyle={{ objectFit: 'cover', objectPosition: 'right center' }}
            loading='eager'
            alt='N/A'
            aria-hidden
          />
          <div className='lg:absolute lg:z-10 lg:inset-0 lg:w-full lg:h-full bg-overlay' />
        </div>
        <div className='relative z-20 max-w-page mx-auto p-8 bg-gray-light lg:bg-transparent'>
          <h2 className='-ml-1 mb-8 lg:my-16 font-extrabold text-5xl leading-tight'>{heroFullHeadline}</h2>
          <div className='flex flex-col md:flex-row flex-wrap lg:flex-no-wrap lg:justify-between h-auto -mx-4'>
            <NavCards {...newDocument} />
          </div>
        </div>
      </div>
      <div className='max-w-page py-8'>
        <Featured articleId={getFeaturedContent()} />
      </div>
      <div className='bg-cyan'>
        <div className='max-w-page pt-4'>
          <div className='flex flex-col lg:flex-row items-center lg:items-end lg:px-32'>
            <div className='flex flex-col lg:w-7/12 lg:pb-32'>
              <div className='headline-2 mt-4 mb-4'>{mission.headline}</div>
              <div>
                <div className='button button-blue'>
                  <Link to={`/${key}/about/`}>
                    <FormattedMessage id='learn-more' /> <em className='fas fa-caret-right ml-2' />
                  </Link>
                </div>
              </div>
            </div>
            <div className='lg:w-1/2 mt-4 lg:mt-0 lg:pl-8'>
              <img
                src={missionImage + '?fm=jpg&fl=progressive&w=320&q=80&bg=rgb:6fd2ff'}
                className='lg:hidden object-contain object-bottom'
                alt='N/A'
                aria-hidden
              />
              <img
                src={missionImage + '?fm=jpg&fl=progressive&w=960&q=80&bg=rgb:6fd2ff'}
                className='hidden lg:block lg:w-full lg:h-full object-contain object-bottom'
                alt='N/A'
                aria-hidden
              />
            </div>
          </div>
        </div>
      </div>
      <div className='wave-bottom-overlap' />
      <div className='max-w-page py-8'>
        <div className='headline-section-centered mt-24 mb-16'>
          <div>{user ? <FormattedMessage id='top-picks-for-you' /> : <FormattedMessage id='new-and-trending' />}</div>
          <hr aria-hidden />
        </div>
        <NewAndTrending />
      </div>
      <div className='bg-royal-purple pt-8 pb-10'>
        <div className='headline-section-royal-purple-centered'>
          <Link to={`/${key}/faqs/`}>
            <FormattedMessage id='trending-questions' />
          </Link>
          <hr aria-hidden />
        </div>
      </div>
      <TrendingQuestions />
      <div className='max-w-page py-8'>
        <div className='headline-section-centered mt-24 mb-16'>
          <div>
            <FormattedMessage id='stay-in-touch' />
          </div>
          <hr aria-hidden />
        </div>
        <SocialMedia />
        <div className='headline-6 mt-8 text-center'>
          {locale === 'en-US' ? 'We’re on' : '¡También estamos en'}{' '}
          <a href={facebookLink} target='_blank' rel='noreferrer noopener' className='text-blue'>
            <em className='fab fa-facebook-square' /> Facebook
          </a>
          {locale === 'en-US' ? ' and ' : ' y '}
          <a href={twitterLink} target='_blank' rel='noopener noreferrer' className='text-blue'>
            <em className='fab fa-twitter-square' /> Twitter
          </a>
          {locale === 'en-US' ? ' too!' : '!'}
        </div>
      </div>
    </Layout>
  )
}

export default Home

export const homePageQuery = graphql`
  query homePageQuery($locale: String!) {
    home: contentfulHome(node_locale: { eq: $locale }) {
      Title
      metaTitle
      metaDescription
      Hero_Headline
      Hero_Headline_Line_2
      Hero_Image {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageNewborn {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageBaby {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageToddler {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImagePreschooler {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      Hero_NavCard1_Headline
      Hero_NavCard1_Subline
      Hero_NavCard1_ExternalLink
      Hero_NavCard2_Headline
      Hero_NavCard2_Subline
      Hero_NavCard2_InternalLink {
        internal {
          type
        }
        node_locale
        slug
        type
      }
      Hero_NavCard3_Headline
      Hero_NavCard3_Subline
      Hero_NavCard3_InternalLink {
        internal {
          type
        }
        node_locale
        slug
      }
      Hero_NavCard4_Headline
      Hero_NavCard4_Subline
      Hero_NavCard4_InternalLink {
        internal {
          type
        }
        node_locale
        slug
        type
      }
      Mission_Headline
      Mission_CTALabel
      Mission_BackgroundImage {
        file {
          url
        }
      }
      FeaturedContent {
        contentful_id
      }
      featuredContentNewborn {
        contentful_id
      }
      featuredContentBaby {
        contentful_id
      }
      featuredContentToddler {
        contentful_id
      }
      featuredContentPreschooler {
        contentful_id
      }
    }
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      facebookLink
      twitterLink
    }
  }
`
